import Head from 'next/head';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import Analytics from '@/components/Analytics';
import { userModelContainer } from '@/store/userModel';
import seo from '../utils/seo';
import policy from '../utils/csp';
import { isMobileDevice } from '@/utils/browser';
import { GlobalStoreContainer, h5Routes } from '@/store';

const SiteChange = dynamic(() => import('../components/SiteChange'), { ssr: false });

require('../styles/globals.less');

const isProd = process.env.NODE_ENV === 'production';

function MyApp({ Component, pageProps, isMobile }) {
  const Layout = Component.Layout;
  const faviconUrl = Component.faviconUrl;
  const router = useRouter();
  const pageSeo = seo[router.asPath] || seo[router.route];
  const { isH5 } = GlobalStoreContainer.useContainer();

  return (
    <ConfigProvider locale={zhCN}>
      <userModelContainer.Provider>
        <Head>
          <link rel="icon" href={faviconUrl || '/favicon.png'} />
          {pageSeo?.title && <title>{pageSeo.title}</title>}
          {pageSeo?.description && <meta name="description" content={pageSeo.description} />}
          {pageSeo?.keywords && <meta name="keywords" content={pageSeo.keywords} />}
          <meta httpEquiv="Content-Security-Policy" content={`default-src ${policy.join(' ')};`} />
          <meta name="baidu-site-verification" content="code-oeJOzq8V4y" />
          <meta
            name="google-site-verification"
            content="xq-IDPeASKoX3OZEX4o757RJdzfvzCAFTjjVm4tSQo4"
          />
          <meta name="msvalidate.01" content="88EA150B65F23A9E74B3B796432AF511" />
          {isH5 && (
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          )}
        </Head>

        {isProd && <Analytics />}

        <SiteChange />

        {Layout ? (
          <Layout>
            <Component {...pageProps} isMobile={isMobile} />
          </Layout>
        ) : (
          <Component {...pageProps} isMobile={isMobile} />
        )}
      </userModelContainer.Provider>
    </ConfigProvider>
  );
}

function MyAppContainer(props) {
  return (
    <GlobalStoreContainer.Provider initialState={props.pageProps}>
      <MyApp {...props} />
    </GlobalStoreContainer.Provider>
  );
}

// 把所有页面变成SSR (SSG时useRouter初始化时query为空，初始化时判断路由参数会出现问题)
MyAppContainer.getInitialProps = async (appContext) => {
  const { req } = appContext.ctx;
  const userAgent = req ? req.headers['user-agent'] : '';
  const isMobile = isMobileDevice(userAgent);
  const isMobileRoute = isMobile && h5Routes.some((r) => req.url.startsWith(r));

  return { pageProps: { isMobile, isMobileRoute } };
};

export default MyAppContainer;
