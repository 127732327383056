export const track = function (params: {
  [key: string]: any;
  name: string;
  keyword: string;
  userId?: string;
}) {
  const { name, keyword, userId, ...rest } = params;
  if (window.zhuge) {
    window.zhuge.track(name, { keyword: keyword, user_id: userId, ...rest });
  } else {
    delayTrack(params);
  }
};

const delayTrack = (params: any, num: number = 1) => {
  if (num > 5) return;
  if (window.zhuge) {
    track(params);
  } else {
    const delay = 500;
    setTimeout(() => {
      delayTrack(params, num + 1);
    }, delay);
  }
};
