type ISEO = Record<
  string,
  {
    title?: string;
    description?: string;
    keywords?: string;
  }
>;

const SEO: ISEO = {
  '/': {
    title: 'TextIn-AI智能文档处理-图像处理技术-大模型加速器-在线免费体验',
    description:
      'TextIn 合合信息旗下一站式智能文档处理平台，专注智能文字识别17年，为企业|开发者|个人提供图像文档识别与处理、文件转换、大模型加速等服务,无缝对接OA/ERP等办公系统，支持云端API服务、端侧SDK、私有化部署。',
    keywords:
      'OCR，深度学习，AI，文字识别，图像识别，表格识别，身份证识别，发票识别，银行卡识别，营业执照识别，图像技术，在线免费识别，合合AI开放平台，文档智能化，Document AI',
  },
  '/product/list': {
    title: 'TextIn产品中心 - 产品列表',
  },
  '/product/textin_text': {
    title: '通用文字识别-文字识别-多语言识别-手写体识别-TextIn',
    description:
      'TextIn基于自研文字识别，覆盖文字、文档、表格、印章、二维码、公式、等多种通用场景，提供全球50+主流语言的印刷体、手写体的高精度识别能力。可用于纸质文档电子化、办公文档/报表识别、教育类文本识别、快递面单识别等场景。',
    keywords:
      '文字识别，文本识别，表格识别，精准识别，文字ocr，办公文档识别，OCR，切边增强，印章识别，快递单识别，财报识别，PS检测，文字识别SDK',
  },
  '/product/textin_bill': {
    title: '票据识别-票据验真-票据ocr-小票识别-TextIn',
    description:
      'TextIn基于智能文字识别技术，识别医疗发票、增值税发票、全电发票、火车票、出租车发票、飞机行程单、网约车行程单等多种国内常见票据，同时支持各类金融票据、海外票据识别，可应用于票据信息录入、企业报销、审计校对对等多种场景。',
    keywords: '票据分类，发票识别，增票识别，火车票识别，发票验真，车牌号识别，OCR，票据SDK',
  },
  '/product/textin_card': {
    title: '卡证文字识别-身份证识别-银行卡识别-名片识别-TextIn',
    description:
      'TextIn基于智能文字识别技术，融合不同行业和场景，支持身份证、银行卡、营业执照、名片、驾驶证、港澳台证件等多种常见卡证高精准度识别，可应用于用户注册、身份认证、金融开户、交通出行、政务办事等多种场景。',
    keywords:
      '身份证识别，名片识别，银行卡识别，营业执照识别，发票识别，驾驶证识别，企业证照识别，个人卡证识别，OCR，卡证SDK',
  },
  '/product/textin_image': {
    title: '图像处理-水印去除-切边增强-摩尔纹去除-TextIn',
    description:
      '基于计算机图形图像技术，提供图像扫描件化、切边增强、弯曲矫正、阴影处理、手写擦除等多种图像处理能力，解决影像采集不规范问题，优化影像质量',
    keywords: '切片增强，图像处理，擦除手写文字，PS检测，图像风险检测，印章检测，OCR',
  },
  '/product/textin_financial': {
    title: 'TextIn财报机器人 - 智能高效的AI财报管理平台免费试用',
    description:
      'TextIn财报机器人基于自研的智能文字识别、表格识别、自然语言处理技术，打造全智能AI财报管理平台，用于财报采集录入、财务风险控制、贷前、贷后财务分析等场景，面向银行、融资租赁、证券等泛金融行业。',
    keywords:
      'TextIn 财报机器人、财报机器人、财报管理、财报录入、财务报表、表格识别、AI财报管理、财报分析、智能财报分析、财报识别、财报采集、风险控制、风控、信审、贷前审核',
  },
  '/product/textin_contract': {
    title: '合同比对-合同信息提取-合同机器人-合同ocr-TextIn',
    description:
      'TextIn 合同机器人基于TextIn深度学习识别技术和NLP算法，将合同签订前后文本进行精准比对和智能纠错，提高效率和控制风险。',
    keywords:
      '合同比对、智能合同、AI合同产品、合同抽取、合同提取、合同审阅、合同审查、合同管理、合同机器人、TextIn、TextIn 合同机器人、人工智能合同',
  },
  '/product/textin_conversion': {
    title: '文件格式转换-PDF转Word-JPG转PDF-PDF转图片',
    description:
      'TextIn提供PDF/Word/Excel/PPT及图片多种格式的高精度转换，高保真输出，并支持自定义水印等，提升文件处理效率。可用于教育文件处理、办公文档处理等场景。',
    keywords:
      '图片转word，PDF转word，PDF转excel，PDF转PPT，PDF转图片，word转PDF,excel转PDF，图片转PDF，word转PDF，文件格式转换，文档格式转换，免费文档格式转换，免费格式转换',
  },
  '/product/textin_mobile_sdk': {
    title: 'TextIn - MobileSDK',
  },
  '/market/list': {
    title: 'TextIn产品市场 - 文字识别免费使用',
    description:
      'TextIn 合合旗下智能文字识别品牌，专注智能文字识别16年，为扫描全能王、名片全能王提供OCR能力，是集智能文字识别技术、产品、服务于一身的一站式OCR服务平台，为全球数千家企业及上亿用户提供服务。',
    keywords:
      'TextIn，OCR，文字识别，身份证识别，增值税发票识别，票据分类，银行卡识别，营业执照识别，发票识别，驾驶证识别，企业证照识别，个人卡证识别，OCR，深度学习，AI',
  },
  '/market/list?tab=5': {
    title: '文件格式转换-PDF转Word-JPG转PDF-PDF转图片-TextIn',
    description:
      'TextIn提供PDF/Word/Excel/PPT及图片多种格式的高精度转换，高保真输出，并支持自定义水印等，提升文件处理效率。可用于教育文件处理、办公文档处理等场景。',
    keywords:
      '图片转word，PDF转word，PDF转excel，PDF转PPT，PDF转图片，word转PDF,excel转PDF，图片转PDF，word转PDF，文件格式转换，文档格式转换，免费文档格式转换，免费格式转换',
  },
  '/document/list': {
    title: 'TextIn文档中心 - 文档与支持',
  },
  '/doc/searchList': {
    title: 'TextIn文档中心 - 搜索列表',
  },
  '/experience/list': {
    title: 'TextIn体验中心 - 在线免费体验中心',
    description: 'TextIn OCR免费在线体验，支持上传图片后即可查看OCR结果',
    keywords:
      'OCR，文字识别，身份证识别，增值税发票识别，票据分类，银行卡识别，营业执照识别，发票识别，驾驶证识别，企业证照识别，个人卡证识别，OCR，深度学习，AI，OCR体验',
  },
  '/contact': {
    title: 'TextIn - 联系我们',
    description: '联系TextIn团队',
    keywords: '联系我们，咨询，客服，建议',
  },
  '/about': {
    title: 'TextIn - 关于我们',
  },
  '/news/list': {
    title: 'TextIn - 资讯中心',
  },
};

export default SEO;
