import { clearInfo, env, getEnv } from '@/utils';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import dayjs from 'dayjs'; //引入
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';
import { track } from '@/utils/track';
import { cookieKey } from '@/pages/activate/contract';
import { useCreation } from 'ahooks';

export interface IUserInfo {
  name: string;
  account: string;
  mobile: string;
  balance: number; // T币余额
  avatar?: string;
  group?: string;
  tags?: {
    key: string;
    label: string;
  }[];
  company?: string; // 公司名称
  code?: string; // 推荐码
  email: string;
  [key: string]: any;
}
const initialUserInfo: IUserInfo = {
  name: '',
  account: '',
  email: '',
  mobile: '',
  balance: 0,
};
function useStore() {
  const [userInfo, setUserInfo] = useState<IUserInfo>(initialUserInfo);
  const router = useRouter();
  const [markList, setmarkList] = useState<boolean>(false);
  const [bannerStatus, setBannerStatus] = useState<boolean>(false);
  const [showModalStatus, setShowModalStatus] = useState<boolean>(false);
  const [closeMarketData, setCloseMarketData] = useState<any>();
  const [closeExperienceData, setCloseExperienceData] = useState<any>();
  const data = useCreation(() => dayjs(new Date()).format('YYYY-MM-DD'), []);
  const [displayType, setDisplayType] = useState<any>();

  useEffect(() => {
    /* 下线广告营销 */
    if (
      bannerStatus &&
      (router.asPath === '/market/list' ||
        router.asPath === '/product/textin_text' ||
        router.asPath === '/product/textin_card' ||
        router.asPath === '/product/textin_bill' ||
        router.asPath === '/product/textin_image' ||
        router.asPath === '/product/textin_conversion')
    ) {
      // setmarkList(true);
    } else {
      setmarkList(false);
    }
    const marketData = localStorage.getItem('closeMarketData');
    const experienceData = localStorage.getItem('closeExperienceData');
    setCloseMarketData(marketData);
    setCloseExperienceData(experienceData);
    if (
      router.asPath.includes('/market/list') &&
      (!marketData || (marketData && marketData === data))
    ) {
      setShowModalStatus(true);
    } else if (
      router.asPath.includes('/experience/') &&
      (!experienceData || (marketData && experienceData === data))
    ) {
      setShowModalStatus(true);
    } else {
      setShowModalStatus(false);
    }
  }, [router.route, bannerStatus]);

  const onClose = () => {
    if (router.asPath === '/') {
      track({
        name: '广告点击',
        keyword: '官网首页——横幅广告——关闭',
        userId: userInfo ? userInfo.openId : '',
      });
    } else if (router.asPath === '/product/textin_text') {
      track({
        name: '广告点击',
        keyword: '通用文字识别——横幅广告——关闭',
        userId: userInfo ? userInfo.openId : '',
      });
    } else if (router.asPath === '/product/textin_card') {
      track({
        name: '广告点击',
        keyword: '卡证文字识别——横幅广告——关闭',
        userId: userInfo ? userInfo.openId : '',
      });
    } else if (router.asPath === '/product/textin_bill') {
      track({
        name: '广告点击',
        keyword: '票据文字识别/验真———横幅广告——关闭',
        userId: userInfo ? userInfo.openId : '',
      });
    } else if (router.asPath === '/product/textin_image') {
      track({
        name: '广告点击',
        keyword: '图像智能处理——横幅广告——关闭',
        userId: userInfo ? userInfo.openId : '',
      });
    } else if (router.asPath === '/product/textin_conversion') {
      track({
        name: '广告点击',
        keyword: '文档格式转换——横幅广告——关闭',
        userId: userInfo ? userInfo.openId : '',
      });
    } else if (router.asPath === '/market/list') {
      track({
        name: '广告点击',
        keyword: '市场列表——横幅广告——关闭',
        userId: userInfo ? userInfo.openId : '',
      });
    }
    setmarkList(() => false);
  };
  const onCloseModal = () => {
    if (
      router.asPath.includes('/market/list') &&
      (!closeMarketData || (closeMarketData && closeMarketData === data))
    ) {
      window.localStorage.setItem('closeMarketData', dayjs().add(1, 'day').format('YYYY-MM-DD'));
      track({
        name: '广告点击',
        keyword: '市场列表——弹窗广告——关闭',
        userId: userInfo ? userInfo.openId : '',
      });
    } else if (
      router.asPath.includes('/experience/') &&
      (!closeExperienceData || (closeExperienceData && closeExperienceData === data))
    ) {
      track({
        name: '广告点击',
        keyword: '体验中心——弹窗广告——关闭',
        userId: userInfo ? userInfo.openId : '',
      });
      window.localStorage.setItem(
        'closeExperienceData',
        dayjs().add(1, 'day').format('YYYY-MM-DD'),
      );
    }
    setShowModalStatus(false);
  };
  const saveUserInfo = (info) => {
    setUserInfo((userInfo) => ({
      ...userInfo,
      ...info,
    }));
    Sentry.setUser({ id: info.openId, username: info.account });
  };
  const clearUserInfo = () => {
    clearInfo();
    setUserInfo({ ...initialUserInfo });
  };
  // 清除合同的token
  const clearContract = () => {
    const envPrefix = getEnv() === env.TEST ? 'test_' : '';
    const doc_compare_user = Cookies.get(`${envPrefix}doc_compare_userName`);
    if (doc_compare_user === userInfo?.account) {
      Cookies.remove(`${envPrefix}doc_compare_userName`, { expires: 1, domain: 'textin.com' });
      Cookies.remove(`${envPrefix}doc_compare_token`, { expires: 1, domain: 'textin.com' });
      Cookies.remove(envPrefix + cookieKey, { expires: 1, domain: 'textin.com' });
      console.log('清除合同token', Cookies);
    }
  };
  return {
    userInfo,
    saveUserInfo,
    clearUserInfo,
    markList,
    setmarkList,
    setBannerStatus,
    onClose,
    showModalStatus,
    setShowModalStatus,
    onCloseModal,
    clearContract,
    displayType,
    setDisplayType,
  };
}

export const userModelContainer = createContainer(useStore);
